<template>
  <div class="progres">
    <!-- <el-row>
      <el-col :span="6">
        <el-input
          size="mini"
          v-model="ToExamineParams.keywords"
          @keyup.enter.native="getsearchToExamine"
          clearable
          placeholder="请输入关键字"
        ></el-input>
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button size="mini" type="primary" @click="getsearchToExamine"
          >搜 索</el-button
        >
      </el-col>
      <el-col :span="1.5" style="padding-left: 20px">
        <el-button
          type="success"
          icon="el-icon-edit"
          :disabled="single"
          @click="handleUpdate"
          >修改</el-button
        >
      </el-col> -->
    <!-- <el-col :span="1.5" style="padding-left: 20px">
        <el-button
          type="danger"
          icon="el-icon-delete"
          :disabled="multiple"
          @click="handleDelete"
          >删除</el-button
        >
      </el-col>
    </el-row> -->

    <el-table
      :data="tableData"
      @selection-change="handleSelectionChange"
      style="width: 100%; margin-top: 20px"
    >
      <!-- <el-table-column fixed type="selection" width="30" /> -->
      <el-table-column fixed type="expand">
        <template slot-scope="props">
          <el-form label-position="left" inline class="demo-table-expand">
            <el-row
              v-for="(iten, index) in props.row.apply_schools"
              :key="index"
            >
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item :label="'申请学校(' + Number(index + 1) + ')'">
                  <span>{{ iten.apply_school }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="2.4" style="height: 40px; padding-left: 60px">
                <el-form-item label="申请专业">
                  <span>{{ iten.specialty }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column prop="username" label="账号"> </el-table-column>
      <el-table-column fixed prop="realname" label="姓名"> </el-table-column>
      <el-table-column fixed prop="gender" label="性别">
        <template slot-scope="scope">
          {{ scope.row.gender == "0" ? "女" : "男" }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="student_num" label="学号"> </el-table-column>
      <el-table-column prop="email" label="邮箱地址"> </el-table-column>
      <el-table-column prop="average_gpa" label="平均GPA"> </el-table-column>
      <el-table-column prop="specialist_rank" label="专业排名">
      </el-table-column>
      <el-table-column prop="" label="委托老师">
        <template slot-scope="scope">
          {{ TranslationRealname(scope.row.teacher_id) }}
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="is_credit_student"
        label="是否是信用管理专业学生"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.is_credit_student == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="isin_my_class" label="是否上过我的课" width="150">
        <template slot-scope="scope">
          {{ scope.row.isin_my_class == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_finance_school"
        label="是否是金融学院学生"
        width="150"
      >
        <template slot-scope="scope">
          {{ scope.row.is_finance_school == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="is_collaborate_project"
        label="是否有合作过研究或项目"
        width="180"
      >
        <template slot-scope="scope">
          {{ scope.row.is_collaborate_project == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="season" label="开始上我的课的季度" width="150">
        <template slot-scope="scope">
          {{ translateSeason(scope.row.season) }}
        </template>
      </el-table-column>
      <el-table-column prop="stu_year" label="开始上我的课的时间" width="150">
        <template slot-scope="scope"> {{ scope.row.stu_year }}年 </template>
      </el-table-column>
      <el-table-column prop="is_dissert" label="是否写课程论文" width="150">
        <template slot-scope="scope">
          {{ scope.row.is_dissert == "0" ? "否" : "是" }}
        </template>
      </el-table-column>
      <el-table-column prop="paper_title" label="所写论文名称" width="150">
      </el-table-column>
      <el-table-column
        prop="body_text_1"
        label="正文一"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="body_text_2"
        label="正文二"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="body_text_3"
        label="正文三"
        width="200"
      ></el-table-column> -->
      <el-table-column prop="status" fixed="right" label="进度" width="160">
        <template slot-scope="scope">
          <el-button
            disabled
            v-if="scope.row.status == 0"
            size="mini"
            type="warning"
            plain
            >待审核</el-button
          >
          <el-button
            disabled
            v-if="scope.row.status == 1"
            size="mini"
            type="primary"
            plain
            >已审核</el-button
          >
          <el-button
            disabled
            v-if="scope.row.status == 2"
            size="mini"
            type="success"
            plain
            >已生成</el-button
          >
          <el-button type="info" size="mini" @click="details(scope.row)" plain
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="ToExamineParams.pageNum"
      :limit.sync="ToExamineParams.pageSize"
      @pagination="getsearchToExamine"
    />

    <!-- 详情查看 -->
    <el-dialog title="详情查看" :visible.sync="detailsState" width="60%">
      <el-form
        ref="form"
        class="FormTable"
        :model="form"
        label-position="left"
        label-width="260px"
      >
        <el-row
          style="border-top: 1px solid #000; border-right: 1px solid #000"
        >
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">姓名</div>
            <div class="detailCol">
              {{ form.realname }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">学号</div>
            <div class="detailCol">
              {{ form.student_num }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">性别</div>
            <div class="detailCol">
              {{ form.gender == "1" ? "男" : "女" }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">邮箱</div>
            <div class="detailCol">
              {{ form.email }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">平均GPA</div>
            <div class="detailCol">
              {{ form.average_gpa }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">准备申请几个学校</div>
            <div class="detailCol">
              {{ form.apply_school_num }}
            </div>
          </el-col>
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%">专业排名(前Top)</div>
            <div class="detailCol">
              {{
                form.specialist_rank == "1"
                  ? "1%"
                  : form.specialist_rank == "5"
                  ? "5%"
                  : form.specialist_rank == "10"
                  ? "10%"
                  : form.specialist_rank == "30"
                  ? "10% - 30%"
                  : form.specialist_rank == "50"
                  ? "30% - 50%"
                  : form.specialist_rank == "100"
                  ? "50% - 100%"
                  : ""
              }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">是否信用管理专业</div>
            <div class="detailCol">
              <!-- {{ form.is_credit_student == "1" ? "是" : "否" }} -->
              {{ form.is_credit_student }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">是否上过我的课</div>
            <div class="detailCol">
              {{ form.isin_my_class == "1" ? "是" : "否" }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">是否金融学院</div>
            <div class="detailCol">
              <!-- {{ form.is_finance_school == "1" ? "是" : "否" }} -->
              {{ form.is_finance_school }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">是否合作过研究或项目</div>
            <div class="detailCol">
              {{ form.is_collaborate_project == "1" ? "是" : "否" }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">开始上我的课的季度</div>
            <div class="detailCol">
              {{ translateSeason(form.season) }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">开始上我的课的时间</div>
            <div class="detailCol">{{ form.stu_year }}年</div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">是否写课程论文</div>
            <div class="detailCol">
              {{ form.is_dissert == "1" ? "是" : "否" }}
            </div>
          </el-col>
          <el-col :span="12" class="detailColAll">
            <div class="detailColTitle">所写论文名称</div>
            <div class="detailCol">
              {{ form.is_dissert == 1 ? form.paper_title : "" }}
            </div>
          </el-col>
        </el-row>
        <template v-if="form.apply_school_num > 0">
          <el-row
            v-for="(item, index) in form.apply_school_num"
            :key="index"
            style="border-right: 1px solid"
          >
            <el-col :span="12" class="detailColAll">
              <div class="detailColTitle">申请学校{{ index + 1 }}</div>
              <div class="detailCol">
                {{ form.apply_schools[index].apply_school }}
              </div>
            </el-col>
            <el-col :span="12" class="detailColAll">
              <div class="detailColTitle">申请专业{{ index + 1 }}</div>
              <div class="detailCol">
                {{ form.apply_schools[index].specialty }}
              </div>
            </el-col>
          </el-row>
        </template>
        <el-row style="border-right: 1px solid">
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%">正文一</div>
            <div class="detailCol">
              {{ form.body_text_1 }}
            </div>
          </el-col>
        </el-row>
        <el-row style="border-right: 1px solid">
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%">正文二</div>
            <div class="detailCol">
              {{ form.body_text_2 }}
            </div>
          </el-col>
        </el-row>
        <el-row style="border-right: 1px solid">
          <el-col :span="24" class="detailColAll">
            <div class="detailColTitle" style="width: 20%">正文三</div>
            <div class="detailCol">
              {{ form.body_text_3 }}
            </div>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailscancel">取 消</el-button>
        <!-- <el-button type="primary" @click="detailsconfirm">确 定</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { search_own_form, teacher_list } from "../../api/Administration.js";
export default {
  data() {
    return {
      ToExamineParams: {
        keywords: "",
        pageNum: 1,
        pageSize: 10,
        status: [1]
      },
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      tableData: [],
      teacherList: [],
      total: 0,
      form: {},
      detailsState: false
    };
  },
  created() {
    this.getsearchToExamine();
    this.getsearchteacher();
  },
  watch: {
    $route(to, from) {
      if (to.fullPath == "/progres") {
        this.getsearchToExamine();
        this.getsearchteacher();
      }
    }
  },
  methods: {
    // 获取教师数据列表
    getsearchteacher() {
      teacher_list().then(res => {
        this.teacherList = res.data;
      });
    },
    //老师字段
    TranslationRealname(id) {
      var RealnaneName = "";
      this.teacherList.forEach(item => {
        if (item.teacher_id == id) {
          RealnaneName = item.realname;
        }
      });
      return RealnaneName;
    },
    // 详情查看
    details(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.detailsState = true;
    },
    // 详情取消
    detailscancel() {
      this.form = {};
      this.detailsState = false;
    },
    // 获取为审核的表单数据列表
    getsearchToExamine() {
      search_own_form(this.ToExamineParams).then(res => {
        this.tableData = res.data;
        this.total = res.data.length;
      });
    },
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.form_id);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    // 翻译开始上我的课的季度
    translateSeason(item) {
      let Name = "";
      switch (item) {
        case "spring":
          Name = "春";
          break;
        case "summer":
          Name = "夏";
          break;
        case "autumn":
          Name = "秋";
          break;
        case "winter":
          Name = "冬";
          break;
        default:
          Name = "";
      }
      return Name;
    }
  }
};
</script>

<style>
.progres {
  margin: 0 auto;
}
@media (min-width: 1400px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .progres,
  .box-m {
    width: 1700px;
  }
}
@media (min-width: 992px) {
  .page-m,
  .comp-m,
  .title-m,
  .about-m,
  .bussiness-m,
  .footer-m,
  .progres,
  .box-m {
    width: 90%;
  }
}
.detailColAll {
  box-sizing: border-box;
  display: flex;
  line-height: 40px;
  /* border: 1px solid #000; */
  /* border-right: 1px solid #000; */
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  /* border-top: 1px solid #000; */
}
.detailColTitle {
  border-right: 1px solid #000;
  /* background-color: #BFBFBF; */
  width: 40%;
  font-weight: 600;
  padding-left: 30px;
}
.detailCol {
  /* border-right: 1px solid #000; */
  /* background-color: #BFBFBF; */
  width: 60%;
  color: #000;
  padding-left: 30px;
}
</style>
